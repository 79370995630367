<template>
    <div class="page_content">
        <div class="page_detail">
            <a-divider orientation="left">行程计划信息</a-divider>
            <a-descriptions :column="2">
                <a-descriptions-item label="行程状态">
                    <span v-if="detail.status==1">
                        已出行
                    </span>
                    <span v-if="detail.status==0">
                        未出行
                    </span>
                    <span v-if="detail.status==-1">
                        取消出行
                    </span>
                </a-descriptions-item>
                <a-descriptions-item label="申请人">
                    {{ detail.username }}
                </a-descriptions-item>
                <a-descriptions-item label="部门">
                    {{ detail.department_name }}
                </a-descriptions-item>
                <a-descriptions-item label="出发时间" v-if='detail.type != "hotel" '>
                    {{ detail.start_time }}
                </a-descriptions-item>

                <a-descriptions-item label="出发地点" v-if='detail.type != "hotel" '>
                    {{detail.depart_city||'--'}}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="入住地"  v-else>
			     {{detail.end_address}}
			   </a-descriptions-item> -->
                <a-descriptions-item label="到达时间">
                    {{ detail.end_time }}
                </a-descriptions-item>
                <a-descriptions-item label="到达地点" v-if='detail.type != "hotel" '>
                    {{detail.destination_city||'--'}}
                </a-descriptions-item>

                <a-descriptions-item label="同行人">
                    {{ detail.traveler }}
                </a-descriptions-item>

            </a-descriptions>
            <div v-for="(item,index) in detail.trip_place" :key='item.id'>
                <a-divider orientation="left">行程信息{{index+1}}</a-divider>
                <div class="li_item">
                    <a-descriptions-item label="出发地点">
                        {{ item.start_place }}
                    </a-descriptions-item>
                    <a-descriptions-item label="到达地点" v-if='detail.type != "hotel" '>
                        {{item.end_place||'--'}}
                    </a-descriptions-item>

                    <a-descriptions :column="2">
                        <a-descriptions-item label="是否入住酒店">
                            {{item.is_book}}
                        </a-descriptions-item>
                        <a-descriptions-item  label="预定状态">
                            {{item.is_order}}
                        </a-descriptions-item>
                        <a-descriptions-item label="入住地">
                            {{ item.live_city }}
                        </a-descriptions-item>
                        <a-descriptions-item label="入住时间">
                            {{ item.start_time }}
                        </a-descriptions-item>
                        <a-descriptions-item label="离店时间">
                            {{ item.end_time }}
                        </a-descriptions-item>


                        <!-- <a-descriptions-item label="住宿情况" v-if="detail.is_order=='已预定'"> -->
                            <!-- <a-descriptions-item label="住宿情况" > -->
                        <!-- </a-descriptions-item> -->
                    </a-descriptions>
                    <p style="margin-bottom: 10px;" v-if="detail.is_order=='已预定'">住宿情况：</p>
                    <div style="margin-bottom: 20px;" v-if="detail.is_order=='已预定'">
                        <div class="arpproval_title">
                            <span class="department_appr">入住时间</span>
                            <span class="department_appr">城市</span>
                            <span class="department_appr">酒店</span>
                            <span>金额/晚</span>
                        </div>
                        <div class="arpproval_title arpproval_title1" v-for="(orderinfo,index) in item.order"
                            :key='index'>
                            <span class="department_appr">{{orderinfo.live_time}}</span>
                            <span class="department_appr">{{orderinfo.city}}</span>
                            <span class="department_appr">{{orderinfo.hotel_name}}</span>
                            <span>{{orderinfo.price.toLocaleString()}}元</span>
                        </div>
                    </div>

                    <a-descriptions :column="2">
                        <a-descriptions-item label="交通工具">
                            {{item.vehicle}}
                        </a-descriptions-item>
                        <a-descriptions-item label="急办公务">
                            {{item.is_urgent}}
                        </a-descriptions-item>
                        <a-descriptions-item label="单程/往返">
                            {{item.is_one_way}}
                        </a-descriptions-item>
                        <a-descriptions-item label="需要转车">
                            {{item.is_tranfer}}
                        </a-descriptions-item>
                        <a-descriptions-item :span="2" label="票价预算/人">
                            {{item.budget.toLocaleString()}}元
                        </a-descriptions-item>
                    </a-descriptions>
                </div>

            </div>
            <a-descriptions :column="1">
                <a-descriptions-item v-for="info in detail.summary" :key='info.id' :label="info.username+'的总结'">
                    {{info.summary}}
                </a-descriptions-item>

            </a-descriptions>
            <a-divider orientation="left">关联审批信息</a-divider>
            <div class="li_item">
                <div class="arpproval_title">
                    <span  class="department_appr">审批编号</span>
                    <span>申请类型</span>
                    <span>申请人</span>
                    <span class="department_appr">申请人部门</span>
                    <span class="department_appr">审批提交时间</span>
                    <span>状态</span>
                    <span>操作</span>
                </div>
                <div class="arpproval_title arpproval_title1">
                    <span  class="department_appr">{{detail.approval_data.approval_no}}</span>
                    <span>{{detail.approval_data.type_name}}</span>
                    <span>{{detail.approval_data.username}}</span>
                    <span class="department_appr"> {{detail.approval_data.department_name}}</span>
                    <span class="department_appr">{{detail.approval_data.create_time}}</span>
                    <span v-if="detail.approval_data.status=='-2'">
                        已撤回
                    </span>
                    <span v-else-if="detail.approval_data.status=='-1'">
                        拒绝审批退回
                    </span>
                    <span v-else-if="detail.approval_data.status=='0'">
                        待审批
                    </span>
                    <span v-else-if="detail.approval_data.status=='1'">
                        审批中
                    </span>
                    <span v-else-if="detail.approval_data.status=='2'">
                        已审批
                    </span>
                    <span class="go_see" @click="go_appr">查看</span>
                </div>
            </div>
            <div class="box_form box_form_btns">
                <a-button type="defalut" @click="$router.back()">返回</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    import LookImages from "@/components/LookImages";
    import {
        getTripPlaceDetail
    } from '@/api/evection.js'
    import Avatar from "@/components/small/Avatar";


    export default {
        components: {
            LookImages,
            Avatar
        },
        data() {
            return {
                detail: {
                    approval_data: {},
                    summary: {}
                }
            }
        },
        created() {
            this.get_info()
        },
        methods: {
            to_contract() {
                this.$router.push('contract_detail?id=' + this.detail.contract_id)
            },
            go_appr() {
                this.$router.push({
                    path: 'travel_approval',
                    query: {
                        id: this.detail.approval_id,
                        approval_type_id: this.detail.approval_data.approval_type_id,
                    }
                })
            },
            get_info() {
                getTripPlaceDetail({
                    data: {
                        approval_id: this.$route.query.id,
                        type: this.$route.query.type,
                        plan_id: this.$route.query.plan_id,
                    }

                }).then(res => {
                    this.detail = res.data.list
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .time_line {
        max-width: 600px;
    }

    .avatar_ul {
        display: flex;
        flex-wrap: wrap;

        .avatar {
            margin: 16px 10px 0;
            width: 80px;
        }
    }

    .page_detail {
        margin: 0;
        margin-left: 5%;

    }

    .li_item {
        .title_item {

            width: 100px;
            text-align: right;
            display: inline-block;
            color: #000;
        }
    }

    .arpproval_title {
        height: 48px;
        padding: 15px 0;
        text-align: center;
        background: rgba(245, 245, 245, 1);

        span {
            width: 7%;
            display: inline-block;
        }

        .department_appr {
            width: 23%;
        }
    }

    .arpproval_title1 {
        height: auto;
        background: none;
        border: 1px solid rgba(245, 245, 245, 1);
    }

    .go_see {
        color: #1890ff;
    }
</style>
